import React from 'react';

export default function AlertesSvgIcon() {
  return (
    <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_22_1444)">
        <path
          d="M13.0253 5.30184L2.5785 22.7419C2.36311 23.115 2.24914 23.5379 2.24793 23.9686C2.24673 24.3993 2.35833 24.8228 2.57162 25.197C2.78492 25.5712 3.09248 25.8831 3.46371 26.1015C3.83494 26.32 4.25689 26.4374 4.68759 26.4421H25.5812C26.0119 26.4374 26.4338 26.32 26.8051 26.1015C27.1763 25.8831 27.4839 25.5712 27.6972 25.197C27.9105 24.8228 28.022 24.3993 28.0208 23.9686C28.0196 23.5379 27.9057 23.115 27.6903 22.7419L17.2435 5.30184C17.0236 4.93935 16.714 4.63966 16.3446 4.43166C15.9752 4.22367 15.5583 4.1144 15.1344 4.1144C14.7104 4.1144 14.2936 4.22367 13.9242 4.43166C13.5548 4.63966 13.2452 4.93935 13.0253 5.30184V5.30184Z"
          stroke="#F22B2B"
          strokeWidth="2.46678"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.1344 11.6414V16.575"
          stroke="#F22B2B"
          strokeWidth="2.46678"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.1344 21.5086H15.1467"
          stroke="#F22B2B"
          strokeWidth="2.46678"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_22_1444">
          <rect width="29.6013" height="29.6013" fill="white" transform="translate(0.33374 0.540955)" />
        </clipPath>
      </defs>
    </svg>
  );
}
