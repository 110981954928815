import * as React from 'react';
import { navigate } from 'gatsby';
import { matchSorter } from 'match-sorter';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';

interface AutocompleteOption {
  label: string;
  ville: string;
  departement: string;
  path: string;
}

const isAutocompleteOption = (value?: AutocompleteOption | string): value is AutocompleteOption => {
  return !!value && typeof value !== 'string';
};

const filterOptions = (options: AutocompleteOption[], { inputValue }: { inputValue: string }) =>
  matchSorter<AutocompleteOption>(options, inputValue, { keys: ['label', 'ville', 'departement'] });
export interface HomeAutocompleteProps {
  options: AutocompleteOption[];
}

export default function HomeAutocomplete({ options }: HomeAutocompleteProps) {
  return (
    <Autocomplete
      freeSolo
      openOnFocus={false}
      disableClearable
      clearOnBlur
      options={options}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Recherchez votre plage"
          variant="outlined"
          placeholder="Département, ville ou plage"
          fullWidth
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            type: 'search',
          }}
        />
      )}
      getOptionLabel={(value: AutocompleteOption) => {
        if (isAutocompleteOption(value)) {
          return `${value.label} (${value.ville})`;
        }

        return value;
      }}
      onChange={(e, value?: AutocompleteOption | string) => {
        if (isAutocompleteOption(value)) {
          navigate(value.path);
        }

        return value;
      }}
    />
  );
}
