import * as React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { Link } from 'gatsby-theme-material-ui';
import { Box, Typography, Divider, Grid } from '@mui/material';
import Layout from '../components/Layout';
import MeteoSvgIcon from '../components/icon/svg/MeteoSvgIcon';
import TemperatureAirEauSvgIcon from '../components/icon/svg/TemperatureAirEauSvgIcon';
import VentSvgIcon from '../components/icon/svg/VentSvgIcon';
import EtatDeLaMerSvgIcon from '../components/icon/svg/EtatDeLaMerSvgIcon';
import ServicesSvgIcon from '../components/icon/svg/ServicesSvgIcon';
import AlertesSvgIcon from '../components/icon/svg/AlertesSvgIcon';
import HomeAutocomplete from '../components/HomeAutocomplete';
import { PartialPlageWithPath } from '../infrastructure/model/Gatsby/VilleNodeInterface';

export interface IndexPageProps {
  data: {
    site: { siteMetadata: Queries.SiteSiteMetadata };
    allPlage: { nodes: PartialPlageWithPath[] };
  };
}

const IndexPage = ({
  data: {
    site: {
      siteMetadata: { title },
    },
    allPlage: { nodes: plages },
  },
}: IndexPageProps) => {
  return (
    <Layout>
      <Helmet htmlAttributes={{ lang: 'fr' }} title={title} />
      <title>{title}</title>
      <Box px={2}>
        <Typography variant="h1" align="center" sx={{ fontSize: '1.625rem' }} py={2}>
          {title}
        </Typography>
        <Typography variant="subtitle2">
          Laplage.info est un service d’information sur la qualité de l’eau de baignade, la météo, la couleur du
          drapeau, les services disponibles...
        </Typography>
      </Box>

      <Box py={4}>
        <HomeAutocomplete
          options={plages.map(({ nom, path, ville: { nom: ville, departement } }) => ({
            label: nom,
            path,
            ville,
            departement,
          }))}
        />
      </Box>

      <Divider variant="middle" />

      <Box py={4}>
        <Typography variant="h2">Informations disponibles pour chaque plage</Typography>
        <Grid container spacing={2}>
          <Grid item xs={4} sx={{ display: 'flex' }} flexDirection="column" alignItems="center">
            <MeteoSvgIcon />
            <Typography variant="body1">Météo</Typography>
          </Grid>
          <Grid item xs={4} sx={{ display: 'flex' }} flexDirection="column" alignItems="center">
            <TemperatureAirEauSvgIcon />
            <Typography variant="body1" textAlign="center">
              Température air/eau
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ display: 'flex' }} flexDirection="column" alignItems="center">
            <VentSvgIcon />
            <Typography variant="body1">Vent</Typography>
          </Grid>

          <Grid item xs={4} sx={{ display: 'flex' }} flexDirection="column" alignItems="center">
            <EtatDeLaMerSvgIcon />
            <Typography variant="body1">Etat de la mer</Typography>
          </Grid>
          <Grid item xs={4} sx={{ display: 'flex' }} flexDirection="column" alignItems="center">
            <ServicesSvgIcon />
            <Typography variant="body1">Services</Typography>
          </Grid>
          <Grid item xs={4} sx={{ display: 'flex' }} flexDirection="column" alignItems="center">
            <AlertesSvgIcon />
            <Typography variant="body1">Alertes</Typography>
          </Grid>
        </Grid>
      </Box>

      <Divider variant="middle" />

      <Box py={4}>
        <Typography variant="body1">
          Vous êtes une collectivité interessée par le projet :{' '}
          <Link target="_blank" rel="noopener" href="https://www.frequence-sud.fr/pro/new_user_pro.php">
            cliquez ici pour nous contacter
          </Link>
        </Typography>
      </Box>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    allPlage {
      nodes {
        nom
        path: gatsbyPath(filePath: "/{Plage.ville__departement}/{Plage.ville__nom}/{Plage.nom}")
        ville {
          nom
          departement
          codePostal
        }
      }
    }
  }
`;
