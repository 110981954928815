import React from 'react';

export default function MeteoSvgIcon() {
  return (
    <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24.4997" cy="24.6811" r="10.5203" fill="#FFDA18" />
      <path d="M24.4995 9.57753V5.04644" stroke="#FFDA18" strokeWidth="3.02073" strokeLinecap="round" />
      <path d="M24.4995 44.3159V39.7849" stroke="#FFDA18" strokeWidth="3.02073" strokeLinecap="round" />
      <path d="M44.1342 24.6395H39.6031" stroke="#FFDA18" strokeWidth="3.02073" strokeLinecap="round" />
      <path d="M9.39596 24.7228H4.86487" stroke="#FFDA18" strokeWidth="3.02073" strokeLinecap="round" />
      <path d="M38.5408 38.4055L35.3368 35.2015" stroke="#FFDA18" strokeWidth="3.02073" strokeLinecap="round" />
      <path d="M13.6623 14.1608L10.4584 10.9568" stroke="#FFDA18" strokeWidth="3.02073" strokeLinecap="round" />
      <path d="M10.4584 38.4055L13.6624 35.2015" stroke="#FFDA18" strokeWidth="3.02073" strokeLinecap="round" />
      <path d="M35.3368 14.1608L38.5408 10.9568" stroke="#FFDA18" strokeWidth="3.02073" strokeLinecap="round" />
    </svg>
  );
}
